@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: white;
  font-family: "Montserrat", sans-serif;
  color: rgb(229 229 229 / 400);
  width: 100%;
  height: 100dvh;
  scroll-behavior: auto !important;
  overscroll-behavior-x: auto;
  scrollbar-width: none;
}

code {
  font-family: "Montserrat", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.slideshow-container {
  position: relative;
  max-width: calc(100% - 64px);
  margin: auto;
  width: 100%;
  height: calc(100dvh - 150px);
  max-height: calc(100dvh - 150px);
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
}

.fade {
  z-index: 1;
  animation-name: fade;
  animation-duration: 2s;
}

.fadeout {
  z-index: -1;
  animation-name: fadeout;
  animation-duration: 2s;
}

@keyframes fade {
  from {
    opacity: 0;
    z-index: 0;
  }
  to {
    z-index: 1;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    z-index: 1;
  }
  to {
    z-index: 0;
    opacity: 0;
  }
}
